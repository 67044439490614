import { Grid } from "@material-ui/core";
import { Cancel, CloudDownload } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useStyle from "./FileUploader.styles";
import classnames from "classnames";
import Dropzone from "react-dropzone";

const FileUploader = ({
  headingText = "File Uploader",
  helperText = "This is helping text",
  state = [],
  setState,
}) => {
  const { t } = useTranslation();
  const styles = useStyle();
  const [showImageDetails, setShowImageDetails] = useState(false);
  const [error, setError] = useState(null);

  const onChange = (uploadedFile) => {
    let file = uploadedFile[0];
    file.image = URL.createObjectURL(uploadedFile[0]);
    setState(file);
    error && setError(null);
  };

  const onError = (files) => {
    let errorFind;
    files.forEach(file => {
      errorFind = file.errors.find(ele => ele.code === 'file-too-large');
    });
    setError(errorFind)
  };

  const onValidation = (file) => {
    if (file.size > 5242880) {
      return {
        code: "file-too-large",
        message: `File size should not exceed 5 MB.`
      }
    }
  };

  const onRemoveButtonClicked = (event) => {
    event.stopPropagation();
    setState({});
    error && setError(null);
  };

  const openInNewTab = (url, event) => {
    event.stopPropagation();
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  return (
    <Dropzone onDropAccepted={onChange} onDropRejected={onError} validator={onValidation}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: classnames(styles.container, { [styles.containerError]: error }) })}>
          <input {...getInputProps()} />
          {Object.keys(state).length === 0 ? (
            <React.Fragment>
              <p className={styles.headingText}>{t(headingText)}</p>
              <p className={styles.helperText}>{t(helperText)}</p>
            </React.Fragment>
          ) : (
            <div
              className={styles.containerImageDiv}
              onMouseLeave={() => setShowImageDetails(false)}
            >
              <div className={classnames(styles.crossIconDiv, { [styles.crossIconDivLeft]: (window.location.pathname === "/user-profile" && state?.file === "remote") })}>
                <Cancel
                  className={styles.crossIcon}
                  onClick={(event) => onRemoveButtonClicked(event)}
                />
              </div>
              {
                (window.location.pathname === "/user-profile" && state?.file === "remote") && (
                  <div className={styles.downloadIconDiv}>
                    <CloudDownload
                      className={styles.crossIcon}
                      onClick={(event) => openInNewTab(state?.url, event)}
                    />
                  </div>
                )
              }
              {state.type.includes("image") ? (
                <React.Fragment>
                  <img
                    className={`${styles.image} ${showImageDetails &&
                      styles.blur
                      }`}
                    alt={state.name}
                    src={state.image}
                    onMouseEnter={() =>
                      setShowImageDetails(true)
                    }
                  />
                  {showImageDetails && (
                    <div className={styles.blurTextDiv}>
                      <p className={styles.fileName}>{state.name}</p>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <Grid
                  className={styles.noneImageTextDiv}
                  container
                  direction="column"
                  justifyContent="center"
                >
                  <p className={styles.fileName}>{state.name}</p>
                </Grid>
              )}
            </div>
          )}
          {error && <p className={styles.errorText}>{t(error.message)}</p>}
        </div>
      )}
    </Dropzone>
  );
};

export default FileUploader;
