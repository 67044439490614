import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./components/Routes/Routes";
import PublicRoute from "./components/Routes/PublicRoutes";
import PrivateRoute from "./components/Routes/PrivateRoutes";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import { useTranslation } from "react-i18next";
import { createTheme } from "@material-ui/core/styles";
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/styles";
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { create } from "jss";
import rtl from "jss-rtl";
import { authActions } from "./store/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import "./assets/css/fonts.css";
import "./App.css";
import { generalReducerActions } from "./store/reducers/generalReducer";
import Layout from "./components/Layout/Layout";
import i18next from "i18next";
import Loader from "./components/Loader/Loader";
import { arSD, enUS } from '@material-ui/core/locale';
import HomePage from "./pages/homepage/HomePage";
import JobDetails from "./pages/jobDetails/JobDetails";

function App() {
  const { i18n, t } = useTranslation();
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const { snackBar } = useSelector(state => state.generalStates);
  const dispatch = useDispatch();

  const closeSnackBar = () => {
    dispatch(generalReducerActions.snackBarUpdate({
      open: false,
      message: "",
      severity: "",
    }));
  };

  const handleRedirection = (lang) => {
    i18next.changeLanguage(lang);
    return (<Redirect to={`${window.location.pathname.slice(0, -3)}`} />);
  }
  useEffect(() => {
    dispatch(generalReducerActions.setDir(i18n.language === "ar" ? "rtl" : "ltr"));
    document.body.dir = i18n.language === "ar" ? "rtl" : "ltr";
    document.documentElement.lang = i18n.language;
  }, [dispatch, i18n.language]);

  useEffect(() => {
    !!localStorage.getItem("LoginId") ?
      dispatch(authActions.alreadyLogin()) :
      dispatch(authActions.logout())
  }, [dispatch]);

  let theme = createTheme({
    direction: i18n.language === "ar" ? "rtl" : "ltr",
  },
    i18n.language === "ar" ? arSD : enUS
  );

  return (
    <React.Fragment>
      {window.location.pathname.includes("/ar") && handleRedirection("ar")}
      {window.location.pathname.includes("/en") && handleRedirection("en")}
      <Loader />
      <Snackbar
        open={snackBar.open}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        autoHideDuration={6000}
        onClose={closeSnackBar}
      >
        <Alert severity={snackBar.severity} onClose={closeSnackBar}>
          <AlertTitle>{t(snackBar.severity.toUpperCase())}</AlertTitle>
          {snackBar.message instanceof Array ? <ul>{snackBar.message.map(ele => <li>{ele}</li>)}</ul> : t(snackBar.message)}
        </Alert>
      </Snackbar>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <Layout />
          <Switch>
            {/* <Layout> */}
            <Route path="/" exact component={HomePage} />
            <Route path="/job-details/:id" exact component={JobDetails} />
            {publicRoutes.map((route, index) => {
              return (
                <PublicRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
            {privateRoutes.map((route, index) => {
              return (
                <PrivateRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
            <Route path="*" exact component={PageNotFound} />
          </Switch>
        </ThemeProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;

/* Adding versions to index.htlm ==> js files */