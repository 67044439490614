import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Dropdown from "../../../components/DropDown/DropDown";
import SearchableDropdown from "../../../components/SearchableDropdown/SearchableDropdown";
import InputField from "../../../components/InputField/InputField";
import { getLookupOptions } from "../../../lookupData.options";
import { generalReducerActions } from "../../../store/reducers/generalReducer";
import { getIssueLocationOptions, getNationalityOptions, getPersonalDetailsById, registrationDataActions, savePersonalDetails } from "../../../store/reducers/registrationReducer";
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyle from "./PersonalInformation.styles";
import InputMask from "react-input-mask";
import { useFormik } from 'formik';
import { getPersonalInfoValidation, initialValues } from "./validation";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";

const PersonalInformation = (props) => {
  const styles = useStyle();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { activeStep, personalDetails, nationalityOptions, issueLocationOptions } = useSelector((state) => state.registration);
  const { dir } = useSelector((state) => state.generalStates);
  const [captchaChecked, setCaptchaChecked] = useState(window.location.pathname === "/registration-form" && false);
  const [selectedNationality, setSelectedNationality] = useState({ label: '' });
  const personalDetailsOptions = useMemo(() => getLookupOptions(dir), [dir]);
  const formattedNationalityOptions = useMemo(() => {
    const options = nationalityOptions?.map((data) => {
      return {
        dropdownValue: data.Key,
        label: dir === "ltr" ? data.Value : data.ValueAR,
      };
    });
    return options;
  }, [dir, nationalityOptions]);

  const formattedIssueLocationOptions = useMemo(() => {
    const options = issueLocationOptions?.map((data) => {
      return {
        dropdownValue: data.Key,
        label: dir === "ltr" ? data.Value : data.ValueAR,
      };
    });
    options.unshift({
      dropdownValue: "",
      label: dir === "ltr" ? "Select" : "Select",
    });
    return options;
  }, [dir, issueLocationOptions]);

  const onClickSubmit = async () => {
    if ((window.location.pathname !== "/user-profile" && !(!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId'))) && !captchaChecked) {
      window.grecaptcha.reset();
      dispatch(generalReducerActions.snackBarUpdate({
        open: true,
        message: `Invalid Captcha`,
        severity: 'warning',
      }));
    } else {
      let data = { ...formik.values };
      if (data.NationalService === "") {
        data = { ...data, NationalService: -1 }
      }
      for (let key in data) {
        if (data[key]?.length === 0) {
          delete data[key];
        }
      }
      if (data.IsResidentNational) {
        delete data.PassportIssueDate;
        delete data.PassportExpiryDate;
        delete data.PassportNumber;
        delete data.IssueLocation;
      }
      if (!data.IsResidentNational) {
        delete data.EmiratesID;
        delete data.Emirate;
      }
      if (window.location.pathname === "/user-profile") {
        data = { ...data, RegistrationId: localStorage.getItem('LoginId'), apikey: localStorage.getItem('apikey') }
      }
      dispatch(generalReducerActions.toggleLoading(true));
      const response = await dispatch(savePersonalDetails(data));
      if (response.payload.status === 200) {
        if (window.location.pathname !== "/user-profile") {
          localStorage.setItem("RegistrationId", response.payload.data.RegistrationId);
          localStorage.setItem("RegistrationStatus", response.payload.data.RegistrationStatus);
          localStorage.setItem("UserData", JSON.stringify(response.payload.data));
        }
        if (window.location.pathname === "/user-profile") {
          dispatch(generalReducerActions.snackBarUpdate({
            open: true,
            message: "Your profile has been updated successfully",
            severity: 'success',
          }));
        }
        dispatch(generalReducerActions.toggleLoading(false));
        dispatch(registrationDataActions.setActiveStep(activeStep + 1));
      } else {
        dispatch(generalReducerActions.toggleLoading(false));
        dispatch(generalReducerActions.snackBarUpdate({
          open: true,
          message: response.payload.data.Message || "Something went wrong. We are having trouble completing your request, please try again",
          severity: 'error',
        }));
      }
    }
  };

  const onChangeRecaptcha = (value) => {
    if (value) {
      setCaptchaChecked(true)
    }
  };

  const onExpiredRecaptcha = () => {
    setCaptchaChecked(false)
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      PasswordRequired: !(!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId'))
    },
    validationSchema: getPersonalInfoValidation(t),
    onSubmit: () => {
      onClickSubmit();
    },
  })

  useEffect(() => {
    async function getDetails() {
      if (window.location.pathname === "/user-profile") {
        dispatch(generalReducerActions.toggleLoading(true));
        const response = await dispatch(getPersonalDetailsById(localStorage.getItem('LoginId')));
        if (response.payload.status === 200) {
          dispatch(generalReducerActions.toggleLoading(false));
        } else {
          dispatch(generalReducerActions.toggleLoading(false));
          dispatch(generalReducerActions.snackBarUpdate({
            open: true,
            message: "Something went wrong. We are having trouble completing your request, please try again",
            severity: 'error',
          }));
        }
      }
      if (!!localStorage.getItem("RegistrationId")) {
        dispatch(generalReducerActions.toggleLoading(true));
        const response = await dispatch(getPersonalDetailsById(localStorage.getItem('RegistrationId')));
        if (response.payload.status === 200) {
          dispatch(generalReducerActions.toggleLoading(false));
        } else {
          dispatch(generalReducerActions.toggleLoading(false));
          dispatch(generalReducerActions.snackBarUpdate({
            open: true,
            message: "Something went wrong. We are having trouble completing your request, please try again",
            severity: 'error',
          }));
        }
      }
    }
    getDetails();
  }, [dispatch]);

  useEffect(() => {
    nationalityOptions.length === 0 && dispatch(getNationalityOptions());
  }, [dispatch, nationalityOptions.length])

  useEffect(() => {
    issueLocationOptions.length === 0 && dispatch(getIssueLocationOptions())
  }, [dispatch, issueLocationOptions.length])

  useEffect(() => {
    if (Object.keys(personalDetails).length > 0) {
      let data = { ...personalDetails, NationalService: personalDetails.NationalService === -1 ? "" : personalDetails.NationalService, PasswordRequired: !(!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')) };
      for (let key in data) {
        if (data[key] === "0001-01-01T00:00:00") {
          data[key] = ""
        }
      }
      formik.setValues({ ...data })
    }
  }, [personalDetails]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        className={styles.container}
        container
        direction="row"
        justifyContent="flex-start"
      >
        <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.FirstName && Boolean(formik.errors.FirstName) })}
          placeholder="First Name"
          name="FirstName"
          value={formik.values.FirstName}
          onChange={formik.handleChange}
          helperText={formik.touched.FirstName && formik.errors.FirstName}
          startAdornment=""
          endAdornment=""
          disabled={!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')}
        />
        <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.SecondName && Boolean(formik.errors.SecondName) })}
          placeholder="Second Name"
          name="SecondName"
          value={formik.values.SecondName}
          onChange={formik.handleChange}
          helperText={formik.touched.SecondName && formik.errors.SecondName}
          startAdornment=""
          endAdornment=""
          disabled={!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')}
        />
        <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.ThirdName && Boolean(formik.errors.ThirdName) })}
          placeholder="Third Name"
          name="ThirdName"
          value={formik.values.ThirdName}
          onChange={formik.handleChange}
          helperText={formik.touched.ThirdName && formik.errors.ThirdName}
          startAdornment=""
          endAdornment=""
          disabled={!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')}
        />
        <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.FamilyName && Boolean(formik.errors.FamilyName) })}
          placeholder="Family Name"
          name="FamilyName"
          value={formik.values.FamilyName}
          onChange={formik.handleChange}
          helperText={formik.touched.FamilyName && formik.errors.FamilyName}
          startAdornment=""
          endAdornment=""
          disabled={!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')}
        />
        <InputMask
          mask="00\971999999999"
          maskChar={null}
          name="MobileNumber"
          value={formik.values.MobileNumber}
          onChange={formik.handleChange}
        >
          {(inputProps) => <InputField
            containerClass={styles.inputField}
            className={classNames({ [styles.error]: formik.touched.MobileNumber && Boolean(formik.errors.MobileNumber) })}
            placeholder="Mobile Number"
            helperText={formik.touched.MobileNumber && formik.errors.MobileNumber}
            {...inputProps}
            startAdornment=""
            endAdornment=""
          />
          }
        </InputMask>
        <Dropdown
          containerClassName={styles.dropdown}
          className={styles.dropdownInput}
          label="Status"
          name="SocialStatus"
          value={formik.values.SocialStatus}
          onChange={formik.handleChange}
          options={personalDetailsOptions.socialStatusOptions}
        />
        <Dropdown
          containerClassName={styles.dropdown}
          className={classNames(styles.dropdownInput, { [styles.error]: formik.touched.IsResidentNational && Boolean(formik.errors.IsResidentNational) })}
          label="Do you have a valid Emirates ID?"
          name="IsResidentNational"
          value={formik.values.IsResidentNational}
          onChange={formik.handleChange}
          helperText={formik.touched.IsResidentNational && formik.errors.IsResidentNational}
          options={personalDetailsOptions.residentNationalOptions}
          disabled={!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')}
        />
        {
          formik.values.IsResidentNational && (
            <>
              <Dropdown
                containerClassName={styles.dropdown}
                className={classNames(styles.dropdownInput, { [styles.error]: formik.touched.Emirate && Boolean(formik.errors.Emirate) })}
                value={formik.values.Emirate}
                onChange={formik.handleChange}
                helperText={formik.touched.Emirate && formik.errors.Emirate}
                label="Emirate"
                name="Emirate"
                options={personalDetailsOptions.emirateOptions}
              />
              <InputMask
                mask="999-9999-9999999-9"
                maskChar={null}
                name="EmiratesID"
                value={formik.values.EmiratesID}
                onChange={formik.handleChange}
                disabled={!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')}
              >
                {(inputPropsMasked) => <InputField
                  containerClass={styles.inputField}
                  className={classNames({ [styles.error]: formik.touched.EmiratesID && Boolean(formik.errors.EmiratesID) })}
                  placeholder="Emirates ID"
                  helperText={formik.touched.EmiratesID && formik.errors.EmiratesID}
                  {...inputPropsMasked}
                  startAdornment=""
                  endAdornment=""
                  disabled={!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')}
                />}
              </InputMask>
            </>
          )
        }
        {formik.values.IsResidentNational === false && (
          <React.Fragment>
            <InputField
              containerClass={styles.inputField}
              className={classNames({ [styles.error]: formik.touched.PassportNumber && Boolean(formik.errors.PassportNumber) })}
              value={formik.values.PassportNumber}
              onChange={formik.handleChange}
              helperText={formik.touched.PassportNumber && formik.errors.PassportNumber}
              placeholder="Passport Number"
              name="PassportNumber"
              startAdornment=""
              endAdornment=""
              disabled={!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')}
            />
            <CustomDatePicker
              placeholder="Passport Issue Date"
              value={formik.values.PassportIssueDate}
              helperText={formik.touched.PassportIssueDate && formik.errors.PassportIssueDate}
              className={classNames(styles.inputField, { [styles.error]: formik.touched.PassportIssueDate && Boolean(formik.errors.PassportIssueDate) })}
              onChange={(date) =>
                formik.handleChange({
                  target: {
                    name: "PassportIssueDate",
                    value: date
                  },
                })
              }
            />

            <CustomDatePicker
              className={classNames(styles.inputField, { [styles.error]: formik.touched.PassportExpiryDate && Boolean(formik.errors.PassportExpiryDate) })}
              value={formik.values.PassportExpiryDate}
              helperText={formik.touched.PassportExpiryDate && formik.errors.PassportExpiryDate}
              placeholder="Passport Expiry Date"
              onChange={(date) =>
                formik.handleChange({
                  target: {
                    name: "PassportExpiryDate",
                    value: date
                  },
                })
              }
            />
            <Dropdown
              containerClassName={styles.dropdown}
              className={classNames(styles.dropdownInput, { [styles.error]: formik.touched.IssueLocation && Boolean(formik.errors.IssueLocation) })}
              label="Issue Location"
              name="IssueLocation"
              value={formik.values.IssueLocation}
              onChange={formik.handleChange}
              helperText={formik.touched.IssueLocation && formik.errors.IssueLocation}
              options={formattedIssueLocationOptions}
            />
          </React.Fragment>
        )}
        <Dropdown
          containerClassName={styles.dropdown}
          className={classNames(styles.dropdownInput, { [styles.error]: formik.touched.FreshGraduate && Boolean(formik.errors.FreshGraduate) })}
          label="Fresh Graduate?"
          name="FreshGraduate"
          value={formik.values.FreshGraduate}
          onChange={formik.handleChange}
          helperText={formik.touched.FreshGraduate && formik.errors.FreshGraduate}
          options={personalDetailsOptions.freshGraduateOptions}
        />
        {
          formik.values.FreshGraduate === true && (
            <InputField
              containerClass={styles.inputField}
              className={classNames({ [styles.error]: formik.touched.TawteenID && Boolean(formik.errors.TawteenID) })}
              value={formik.values.TawteenID}
              onChange={formik.handleChange}
              helperText={formik.touched.TawteenID && formik.errors.TawteenID}
              placeholder="Tawteen ID"
              name="TawteenID"
              startAdornment=""
              endAdornment=""
            />
          )
        }
        <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.Email && Boolean(formik.errors.Email) })}
          value={formik.values.Email}
          onChange={formik.handleChange}
          helperText={formik.touched.Email && formik.errors.Email}
          placeholder="Username should be the email"
          name="Email"
          type="email"
          startAdornment=""
          endAdornment=""
          disabled={!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId')}
        />
        {formik.values.Password !== null && <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.Password && Boolean(formik.errors.Password) })}
          value={formik.values.Password}
          onChange={formik.handleChange}
          helperText={formik.touched.Password && formik.errors.Password}
          type="password"
          placeholder="Password"
          name="Password"
          startAdornment=""
          endAdornment=""
        />}
        <Dropdown
          containerClassName={styles.dropdown}
          className={classNames(styles.dropdownInput, { [styles.error]: formik.touched.Gender && Boolean(formik.errors.Gender) })}
          value={formik.values.Gender}
          onChange={formik.handleChange}
          helperText={formik.touched.Gender && formik.errors.Gender}
          label="Gender"
          name="Gender"
          options={personalDetailsOptions.genderOptions}
        />
        <CustomDatePicker
          className={classNames(styles.inputField, { [styles.error]: formik.touched.DateOfBirth && Boolean(formik.errors.DateOfBirth) })}
          value={formik.values.DateOfBirth}
          helperText={formik.touched.DateOfBirth && formik.errors.DateOfBirth}
          placeholder="Date Of Birth"
          onChange={(date) =>
            formik.handleChange({
              target: {
                name: "DateOfBirth",
                value: date
              },
            })
          }
        />
        <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.PlaceofBirth && Boolean(formik.errors.PlaceofBirth) })}
          value={formik.values.PlaceofBirth}
          helperText={formik.touched.PlaceofBirth && formik.errors.PlaceofBirth}
          placeholder="Place of Birth"
          name="PlaceofBirth"
          onChange={formik.handleChange}
          startAdornment=""
          endAdornment=""
        />
        <Dropdown
          containerClassName={styles.dropdown}
          className={classNames(styles.dropdownInput, { [styles.error]: formik.touched.PeopleofDetermination && Boolean(formik.errors.PeopleofDetermination) })}
          value={formik.values.PeopleofDetermination}
          onChange={formik.handleChange}
          helperText={formik.touched.PeopleofDetermination && formik.errors.PeopleofDetermination}
          label="People of Determination"
          name="PeopleofDetermination"
          options={personalDetailsOptions.peopleofDeterminationOptions}
        />
        {formik.values.PeopleofDetermination && (
          <InputField
            containerClass={styles.inputField}
            className={classNames({ [styles.error]: formik.touched.DisabilityDetail && Boolean(formik.errors.DisabilityDetail) })}
            value={formik.values.DisabilityDetail}
            onChange={formik.handleChange}
            helperText={formik.touched.DisabilityDetail && formik.errors.DisabilityDetail}
            placeholder="Dis-ability Details"
            name="DisabilityDetail"
            startAdornment=""
            endAdornment=""
          />
        )}
        {/* <Dropdown
          containerClassName={styles.dropdown}
          className={classNames(styles.dropdownInput, { [styles.error]: formik.touched.Nationality && Boolean(formik.errors.Nationality) })}
          label="Nationality"
          name="Nationality"
          value={formik.values.Nationality?.toString()}
          onChange={(event) => {
            formik.handleChange(event);
            setSelectedNationality(formattedNationalityOptions.find((element) => element.dropdownValue === event.target.value))
          }}
          options={formattedNationalityOptions}
          helperText={formik.touched.Nationality && formik.errors.Nationality}
        /> */}
        <SearchableDropdown
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.Nationality && Boolean(formik.errors.Nationality) })}
          placeholder="Nationality"
          name="Nationality"
          value={formik.values.Nationality}
          onChange={(_event, value) => {
            formik.handleChange({
              target: {
                name: "Nationality",
                value: value?.dropdownValue || ''
              },
            })
            setSelectedNationality(formattedNationalityOptions.find((element) => element.dropdownValue === value?.dropdownValue) || { label: '' })
          }}
          options={formattedNationalityOptions}
          helperText={formik.touched.Nationality && formik.errors.Nationality}
        />
        <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.CurrentAddress && Boolean(formik.errors.CurrentAddress) })}
          value={formik.values.CurrentAddress}
          onChange={formik.handleChange}
          helperText={formik.touched.CurrentAddress && formik.errors.CurrentAddress}
          placeholder="Current Address"
          name="CurrentAddress"
          startAdornment=""
          endAdornment=""
        />
        <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.City && Boolean(formik.errors.City) })}
          value={formik.values.City}
          onChange={formik.handleChange}
          helperText={formik.touched.City && formik.errors.City}
          placeholder="City"
          name="City"
          startAdornment=""
          endAdornment=""
        />
        <Dropdown
          containerClassName={styles.dropdown}
          className={classNames(styles.dropdownInput, { [styles.error]: formik.touched.PreferredCityToWork && Boolean(formik.errors.PreferredCityToWork) })}
          value={formik.values.PreferredCityToWork}
          onChange={formik.handleChange}
          helperText={formik.touched.PreferredCityToWork && formik.errors.PreferredCityToWork}
          label="Preferred City to Work"
          name="PreferredCityToWork"
          options={personalDetailsOptions.preferredCityToWorkOptions}
        />
        <Dropdown
          containerClassName={styles.dropdown}
          className={classNames(styles.dropdownInput, { [styles.error]: formik.touched.NationalService && Boolean(formik.errors.NationalService) })}
          value={formik.values.NationalService}
          onChange={formik.handleChange}
          helperText={formik.touched.NationalService && formik.errors.NationalService}
          label="National Service"
          name="NationalService"
          options={personalDetailsOptions.nationalServiceOptions}
          disabled={
            (formik.values.Gender === 1 && selectedNationality.label.toLowerCase() === 'united arab emirates') ? false :
              (formik.values.Gender === 1 && selectedNationality.label.toLowerCase() === 'uae') ? false : true}
        />
        {formik.values.NationalService === 0 && (
          <InputField
            containerClass={styles.inputField}
            className={classNames({ [styles.error]: formik.touched.Reason && Boolean(formik.errors.Reason) })}
            value={formik.values.Reason}
            onChange={formik.handleChange}
            helperText={formik.touched.Reason && formik.errors.Reason}
            placeholder="Reason"
            name="Reason"
            startAdornment=""
            endAdornment=""
          />
        )}
        {formik.values.NationalService === 1 && (
          <CustomDatePicker
            className={classNames(styles.inputField, { [styles.error]: formik.touched.NationalServiceDate && Boolean(formik.errors.NationalServiceDate) })}
            value={formik.values.NationalServiceDate}
            helperText={formik.touched.NationalServiceDate && formik.errors.NationalServiceDate}
            placeholder="National Service Date"
            onChange={(date) =>
              formik.handleChange({
                target: {
                  name: "NationalServiceDate",
                  value: date
                },
              })
            }
          />
        )}
        {formik.values.NationalService === 2 && (
          <CustomDatePicker
            className={classNames(styles.inputField, { [styles.error]: formik.touched.ExpectedPerformNationalService && Boolean(formik.errors.ExpectedPerformNationalService) })}
            value={formik.values.ExpectedPerformNationalService}
            helperText={formik.touched.ExpectedPerformNationalService && formik.errors.ExpectedPerformNationalService}
            placeholder="Expected To Perform National Service"
            onChange={(date) =>
              formik.handleChange({
                target: {
                  name: "ExpectedPerformNationalService",
                  value: date
                },
              })
            }
          />
        )}
        <InputField
          containerClass={styles.inputField}
          className={classNames({ [styles.error]: formik.touched.Languages && Boolean(formik.errors.Languages) })}
          value={formik.values.Languages}
          onChange={formik.handleChange}
          helperText={formik.touched.Languages && formik.errors.Languages}
          placeholder="Languages"
          name="Languages"
          startAdornment=""
          endAdornment=""
        />
      </Grid>
      {
        (window.location.pathname !== "/user-profile" && !(!!localStorage.getItem('LoginId') || !!localStorage.getItem('RegistrationId'))) && (
          <React.Fragment>
            <Divider style={{ margin: "30px 0" }} />
            <Grid container direction="row" justifyContent="center" alignItems="center" >
              <ReCAPTCHA
                hl={i18n.language}
                sitekey="6Ld08YQaAAAAAKMjEaZ9u4WMXYfywPVmmjmQUuPM"
                onChange={onChangeRecaptcha}
                onExpired={onExpiredRecaptcha}
              />
            </Grid>
          </React.Fragment>
        )
      }
      <Divider className={styles.divider} />
      <ActionButtons />
    </form>
  );
};

export default PersonalInformation;
