import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import generalReducer from "./reducers/generalReducer";
import jobDataReducer from "./reducers/jobDataReducer";
import registrationReducer from "./reducers/registrationReducer";

const store = configureStore({
  reducer: {
    jobData: jobDataReducer,
    auth: authReducer,
    registration: registrationReducer,
    generalStates: generalReducer
  },
});

export default store;
