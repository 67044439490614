export const getLookupOptions = (dir) => {
  return {
    socialStatusOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Married" : "متزوج/ ة",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Single" : "أعزب/ عزباء",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Divorced" : "مطلق/ة",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Widowed" : "أرمل/ة",
      },
    ],
    residentNationalOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    freshGraduateOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    locationOptions: [
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "ADM" : "بلدية مدينة أبو ظبي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "AAM" : "بلدينة مدينة العين",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "DRM" : "بلدية منطقة الظفرة",
      },
    ],
    hiringEntityOptions: [
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "DMT" : "دائرة البلديات والنقل",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "ADM" : "بلدية مدينة أبو ظبي",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "AAM" : "بلدينة مدينة العين",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "DRM" : "بلدية منطقة الظفرة",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "ITC" : "مركز النقل المتكامل",
      },
    ],
    workNatureOptions: [
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Supervisory" : "إشرافي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Management" : "إداري",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Leadership" : "قيادي",
      },
    ],
    genderOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Male" : "ذكر",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Female" : "أنثى",
      },
    ],
    peopleofDeterminationOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    emirateOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Abu Dhabi" : "أبو ظبي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Dubai" : "دبي",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Sharjah" : "الشارقة",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Umm al-Quwain" : "أم القيوين",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "Fujairah" : "الفجيرة",
      },
      {
        dropdownValue: 6,
        label: dir === "ltr" ? "Ajman" : "عجمان",
      },
      {
        dropdownValue: 7,
        label: dir === "ltr" ? "Ra's al-Khaimah" : "رأس الخيمة",
      },
    ],
    preferredCityToWorkOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Abu Dhabi City" : "مدينة أبو ظبي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Al Ain City" : "مدينة العين",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Al Dhafra Region" : "منطقة الظفرة",
      },
    ],
    nationalServiceOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 0,
        label: dir === "ltr" ? "No" : "لا",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Postponed" : "مؤجلة",
      },
    ],
    status: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Currently working" : "العمل الحالي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Previously working" : "العمل السابق",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Not working" : "لا اعمل",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Other" : "أخرى",
      },
    ],
    educationLevelOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "High School" : "ثانوي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Bachelor" : "بكالوريوس",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Diploma" : "دبلومة",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Master" : "ماجستير",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "PHD" : "دكتوراة",
      },
      {
        dropdownValue: 6,
        label: dir === "ltr" ? "Other" : "أخرى",
      },
    ],
  };
};
